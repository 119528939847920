// Libraries
import React from 'react'
import moment from 'moment'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { ModuleNames } from '../../../constants/componentNames.constants'

// Styles
import { ResourceItem, ResourceRowContainer } from './style'

// Components
import Button from '../../global/button'
import GlobalContainer from '../globalContainer'
import Typography from '../typography'

// Helpers
import options from '../../../utils/richText.utils'
import { getMicrocopyData } from '../../../utils/microcopy.utils'
import ImgUtil from '../../../utils/img.utils'
import { isResourceCategory } from '../../../utils/functions.utils'
import { generateBlogPostSlug } from '../../../utils/blog.utils'
import {
  isExternalURL,
  internalUrl,
  internalLinkHandler,
} from '../../../utils/link.utils'
import { UseCases } from '../../../constants/useCases.constants'

// Images
import DefaultGrid from '../../../../static/steam-img/no-image-grid.svg'
import videoButtonPlay from '../../../../static/steam-img/video-button-play.svg'
import formatInlineCode from '../../../utils/shortTextFormatting'

const ResourceRow = (props: any) => {
  if (props.data) {
    let microcopyset: any = [],
      seeAllItemsIntro: string = '',
      seeAllItemsCta:
        | { externalUrl: string; text: string; internalLink?: internalUrl }
        | undefined,
      featuredPages: any,
      topPadding: string = 'Large',
      bottomPadding: string = 'Large',
      isHomePage: boolean = false
    if (props.isHomePage) isHomePage = props.isHomePage
    if (props.data.microcopyset) microcopyset = props.data.microcopyset
    if (props.data.seeAllItemsIntro)
      seeAllItemsIntro = props.data.seeAllItemsIntro
    if (props.data.seeAllItemsCta) seeAllItemsCta = props.data.seeAllItemsCta
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.featuredPages) featuredPages = props.data.featuredPages
    const resourcePageTypes = [
      ModuleNames.PAGE_CUSTOMER_STORY,
      ModuleNames.PAGE_ANALYST_REPORT,
      ModuleNames.PAGE_WHITE_PAPER,
      ModuleNames.PAGE_PLACEHOLDER,
      ModuleNames.PAGE_RESOURCE_DEMO,
    ]

    const generateCard = (item: any, index: number) => {
      let body
      if (item.__typename === ModuleNames.BLOG_POST) {
        body = item.blogTeaserBody
      } else if (resourcePageTypes.indexOf(item.__typename) > -1) {
        body = item.teaserBody
      } else {
        // ExternalResource
        body = item.body
      }

      if (!body) {
        if (item.__typename === ModuleNames.BLOG_POST)
          body = item.seo?.pageMetaDescription
        else body = item.seoMetadata?.pageMetaDescription
      }

      const category =
        !isHomePage && item.category && item.category.title
          ? item.category.title
          : ''

      const includedDateCategory =
        category === UseCases.RESOURCE_LISTING_WEBINAR ||
        category === UseCases.RESOURCE_LISTING_EVENTS

      let pageLink = ''
      if (item.__typename === ModuleNames.TEASER) {
        if (item.url1) pageLink = item.url1
      } else if (item.slug) {
        if (item.__typename === ModuleNames.BLOG_POST) {
          pageLink = generateBlogPostSlug(item.slug, item?.blogCategory?.name)
        } else
          pageLink = item.slug.charAt(0) === '/' ? item.slug : `/${item.slug}`
      }

      let image = ''
      let altText = ''
      let heading = ''
      if (item.__typename === ModuleNames.PAGE_RESOURCE_DEMO) {
        image = item?.spotlight?.video?.image?.file?.url
        altText = item?.spotlight?.video?.altText
        heading = item?.hero?.headline
      } else {
        image = item.image?.image?.file?.url
        altText = item.image?.altText
        heading = item.title
      }

      const inEnglish = item.inEnglish && !isHomePage ? item.inEnglish : false
      let date = item.date ? item.date : ''
      let number =
        item.category && item.category.number ? item.category.number : ''

      if (item.microcopy) {
        microcopyset = item.microcopy
      }

      let categoryIcon = ''
      let companyLogo = ''
      let videoIcon = ''
      let companyAltText = ''
      let categoryAltText = ''

      //category video demo
      if (!isHomePage) {
        if (isResourceCategory('Video Demo', number)) {
          videoIcon =
            item.category?.colorImage?.image?.file?.url || videoButtonPlay
        } else if (image) {
          companyLogo = item.companyLogo?.image?.file?.url
          if (!companyLogo && item.category?.whiteImage) {
            categoryIcon = item.category.whiteImage?.image?.file?.url
            categoryAltText = item.category.whiteImage.altText
              ? item.category.whiteImage.altText
              : ''
          } else {
            companyAltText = item.companyLogo && item.companyLogo.altText
          }
        } else {
          companyLogo = item.companyColorLogo?.image?.file?.url
          if (!companyLogo && item.category?.colorImage) {
            categoryIcon = item.category.colorImage?.image?.file?.url
            categoryAltText = item.category.colorImage.altText
              ? item.category.colorImage.altText
              : ''
          } else {
            companyAltText =
              item.companyColorLogo && item.companyColorLogo.altText
          }
        }
      }

      let convertedDate
      if (date) {
        convertedDate = new Date(date)
        date = moment(convertedDate).format('MMMM D, YYYY')
      }

      let datePassed = convertedDate && convertedDate < new Date()

      let cta = getMicrocopyData(microcopyset, number, 'primary', 'text')
      const imageOverlay = image ? true : false

      let ctaText, when
      if (
        (isResourceCategory('Webinar', number) ||
          isResourceCategory('Events', number)) &&
        cta &&
        cta.length >= 2 &&
        convertedDate
      ) {
        let now = new Date()
        when = convertedDate >= now ? 'before' : 'after'
        if (cta[0].key && cta[0].key.includes(when)) ctaText = cta[0].value
        else if (cta[1].key && cta[1].key.includes(when)) ctaText = cta[1].value
      }

      // If not webinar, get the first cta text value
      if (!ctaText && cta && cta[0] && cta[0].value) ctaText = cta[0].value

      let linkTarget = '_self'
      if (isExternalURL(pageLink)) {
        linkTarget = '_blank'
      }
      return (
        <ResourceItem
          hasOverlay={imageOverlay}
          className="ResourceRow__Item"
          videoIcon={videoIcon}
          companyLogo={companyLogo}
          key={`ResourceRowItem--${index}`}
          categoryIcon={categoryIcon}
        >
          <a href={pageLink} target={linkTarget}>
            <div className="ResourceRow__Image-Container">
              {companyLogo ? (
                <img
                  className="ResourceRow__Item-Logo"
                  src={ImgUtil.getResizedImageUrl(companyLogo, 'width=240')}
                  alt={companyAltText}
                  width={240}
                  height={240}
                />
              ) : videoIcon ? (
                <img
                  className="ResourceRow__Item-Icon"
                  src={videoIcon}
                  alt="Play button"
                  width={48}
                  height={48}
                />
              ) : categoryIcon ? (
                <img
                  className="ResourceRow__Item-Icon"
                  src={ImgUtil.getResizedImageUrl(categoryIcon, '')}
                  alt={categoryAltText}
                  width={48}
                  height={48}
                />
              ) : (
                ''
              )}

              {inEnglish && (
                <Typography
                  type="microAlt"
                  className="ResourceRow__Pill ResourceRow__Pill--White"
                >
                  IN ENGLISH
                </Typography>
              )}
              {category && category === 'Webinar' && when === 'after' && (
                <Typography
                  type="microAlt"
                  className="ResourceRow__Pill ResourceRow__Pill--Dark"
                >
                  ON DEMAND
                </Typography>
              )}
              <img
                className="ResourceRow__BackgroundImg"
                src={
                  image
                    ? ImgUtil.getResizedImageUrl(image, 'width=400')
                    : DefaultGrid
                }
                alt={altText ? altText : 'Dotted Grid'}
                width={384}
                height={384}
              />
            </div>
            <div className="ResourceRow__Text-Container">
              {!isHomePage && (category || date) && (
                <div className="ResourceRow__Meta-Container">
                  {category && (
                    <Typography
                      type="captionAlt"
                      className="ResourceRow__Item-Category"
                    >
                      {category}
                    </Typography>
                  )}
                  {date && !datePassed && includedDateCategory && (
                    <Typography
                      type="caption"
                      className="ResourceRow__Item-Date"
                    >
                      {date}
                    </Typography>
                  )}
                </div>
              )}
              {heading && (
                <Typography
                  className="ResourceRow__Item-Heading"
                  type="header6"
                >
                  {formatInlineCode(heading)}
                </Typography>
              )}
              {body && (
                <Typography
                  type="bodyXSWrapper"
                  className="ResourceRow__Item-Body"
                >
                  {typeof body === 'string'
                    ? body
                    : renderRichText(body, options)}
                </Typography>
              )}

              {ctaText && (
                <Button
                  icon={true}
                  href={pageLink}
                  text={ctaText}
                  type="text-link"
                  size="small"
                  theme={'light'}
                />
              )}
            </div>
          </a>
        </ResourceItem>
      )
    }
    return (
      <ResourceRowContainer
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        isHomePage={isHomePage}
        data-cy={`ResourceRow${isHomePage ? '-Homepage' : ''}`}
        className="ResourceRowContainer"
      >
        <GlobalContainer className="ResourceRow__Outer-Container">
          <div className="ResourceRow__Card-Container">
            {featuredPages &&
              featuredPages.map((element: any, index: number) =>
                generateCard(element, index)
              )}
          </div>
          {seeAllItemsIntro && (
            <Typography type="bodyXS" className="ResourceRow__SeeAllItemsIntro">
              {seeAllItemsIntro}
            </Typography>
          )}
          {seeAllItemsCta && (
            <div className="ResourceRow__CTA-Container">
              <Button
                icon={true}
                href={
                  seeAllItemsCta.internalLink
                    ? internalLinkHandler(seeAllItemsCta.internalLink)
                    : seeAllItemsCta.externalUrl
                }
                text={seeAllItemsCta.text}
                type="tertiary"
                size="small"
                theme={'light'}
              />
            </div>
          )}
        </GlobalContainer>
      </ResourceRowContainer>
    )
  }

  return null
}

export default ResourceRow
