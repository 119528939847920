import styled from 'styled-components'

export const ContentBlockContainer = styled.div`
  ${({ orangeDivider, alignment, theme, topPadding, bottomPadding }) => {
    let innerStyles = ``

    if (topPadding === 'Large') innerStyles += 'padding-top: 96px;'
    else if (topPadding === 'Small') innerStyles += 'padding-top: 64px;'
    if (bottomPadding === 'Large') innerStyles += 'padding-bottom: 96px;'
    else if (bottomPadding === 'Small') innerStyles += 'padding-bottom: 64px;'

    if (orangeDivider) {
      innerStyles += `
        &:before {
          content: '';
          position: absolute;
          width: 160px;
          height: 4px;
          top: 0;
          background: ${theme.webMktPalette.orange[50]};
        }
      `
    }

    let alignmentStyles = ``
    if (alignment === 'left') {
      alignmentStyles = `
        text-align: left;
        .ContentBlock__Inner-Container:before {
          left: 0;
          transform: none;
        }
        ${theme.breakpoints.down('xs')} {
          .CTA {
            align-self: flex-start;
          }
        }
        .ContentBlock__Body ul {
          position: relative;
          padding-left: 24px;
          li:before {
              position: absolute;
              left: 0;
            }
          }
        }
      `
    } else {
      alignmentStyles = `
        text-align: center;
        .ContentBlock__Inner-Container {
          margin: 0 auto;
          &:before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .ContentBlock__Buttons {
          justify-content: center;
        }
        ${theme.breakpoints.down('xs')} {
          .CTA {
            align-self: center;
          }
        }
        .ContentBlock__Body ul li {
          &:before {
            margin-right: 12px;
          }
        }
      `
    }

    return `
      ${alignmentStyles}

      .ContentBlock__Inner-Container {
        position: relative;
        ${innerStyles}
        max-width: 791px;
        > *:first-child {
          margin-top: 0;
        }
        ${theme.breakpoints.down('md')} {
          max-width: 796px;
        }
        ul {
          list-style: none;
        }
      }
      .ContentBlock__Body {
        margin: 24px auto 0px;
        p, li {
          ${theme.breakpoints.up('md')} {
            font-size: 20px;
            letter-spacing: -0.5px;
            line-height: 32px;
          }
        }

        p {
          margin: 0 0 24px;
        }
        li {
          p {
            display: inline;
            margin: 0;
          }
          margin-bottom: 8px;
          &:before {
            content: "\\2022";
            width: 24px;
          }
        }
        a {
          color: #0067DF;
          &:hover {
            text-decoration: underline;
          }
        }
        > *:last-child {
          margin-bottom: 0;
        }
        ${theme.breakpoints.down('xs')} {
          margin: 16px auto 0;
        }
        p:empty { display:none; }
        &:empty { display:none; }
      }

      .ContentBlock__Fine-Print {
        color: rgba(0, 0, 0, 0.3);
        margin: 32px 0 0;
        p {
          padding: 5px 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        ${theme.breakpoints.down('sm')} {
          margin: 24px 0 0;
        }
      }

      &.ContentBlock--Light {
        background: ${theme.webMktPalette.background.white};
      }
      &.ContentBlock--Grey {
        background: ${theme.webMktPalette.background.gray};
      }
      &.ContentBlock--Dark {
        background: ${theme.webMktPalette.background.dark};
        .ContentBlock__Headline,
        .ContentBlock__Body p,
        .ContentBlock__Body li {
          color: ${theme.webMktPalette.text.primaryWhite};
        }
        .ContentBlock__Body a {
          color: #38c6f4;
        }
        .ContentBlock__Fine-Print {
          color: rgba(255, 255, 255, 0.3);
        }
      }
      .CTA:nth-child(2) {
        margin-left: 32px;
      }
      .ContentBlock__Buttons {
        display: flex;
        align-items: center;
        margin-top: 32px;
        ${theme.breakpoints.down('xs')} {
          flex-direction: column;
          .CTA:nth-child(2) {
              margin: 24px 0 0;
            }
          }
        }
      }
      a + span, .inline-formatter {
        display: inline;
      }
      .ContentBlock__Fine-Print, .ContentBlock__Fineprint-Container p {
        color: rgba(0, 0, 0, 0.3);
      }
    `
  }}

`
