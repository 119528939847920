import styled from 'styled-components'

export const ResourceItem = styled.div`
  > a {
    text-decoration: none;
  }
  ${(p) =>
    p.hasOverlay
      ? `
    .ResourceRow__Image-Container:before {
      content: '';
      background: rgb(0, 0, 0, ${
        p.companyLogo || p.categoryIcon || p.videoIcon ? '.1' : '.05'
      });
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }`
      : ''}

  ${(p) => {
    if (p.companyLogo)
      return `
      .ResourceRow__Item-Logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 120px;
        width: 240px;
        ${p.theme.breakpoints.down('md')} {
          height: 95px;
          width: 190px;
        }
        ${p.theme.breakpoints.down('sm')} {
          height: 113px;
          width: 226px;
        }
        ${p.theme.breakpoints.down('xs')} {
          height: 103px;
          width: 206px;
        }
      }
    `
    let iconStyles = ''
    if (p.categoryIcon || p.videoIcon) {
      iconStyles += `
        position: absolute;
        left: 1px;
        bottom: 1px;
        height: 48px;
        width: 48px;
      `
    } else {
      return ``
    }

    if (p.videoIcon) {
      iconStyles = `
        .ResourceRow__Item-Icon {
          ${p.hasOverlay ? `opacity: .8;` : ''}
          ${iconStyles}
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `
    } else if (p.categoryIcon) {
      iconStyles = `
        .ResourceRow__Item-Icon {
          ${iconStyles}
        }
      `
    }
    return `
      ${iconStyles}
    `
  }}

  &:hover {
    box-shadow: 0px 16px 16px -8px rgba(0, 0, 0, 0.1);
  }

  width: 384px;
  background: #ffffff;
  cursor: pointer;

  .CTA {
    margin: 16px 0 32px;

    ${(p) => p.theme.breakpoints.down('sm')} {
      margin: 16px 0 24px;
    }
  }

  &:not(:last-of-type) {
    margin-right: 24px;
  }

  &.Overlay:before {
    content: '';
    background: rgb(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  ${(p) => p.theme.breakpoints.down('md')} {
    width: 304px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 360px;
    &:nth-of-type(1) {
      margin-right: 16px;
    }
    &:nth-of-type(2) {
      margin: 0;
    }
    &:nth-of-type(3) {
      margin-top: 16px;
    }
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    max-width: 329px;
    width: 100%;
    &:nth-of-type(1) {
      margin: 0;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-top: 16px;
    }
  }
`

export const ResourceRowContainer = styled.div`
  ${(p) => {
    let topPadding, bottomPadding
    if (p.topPadding === 'Large') {
      topPadding = '96px'
    } else if (p.topPadding === 'Small') {
      topPadding = '64px'
    } else {
      topPadding = '0'
    }

    if (p.bottomPadding === 'Large') {
      bottomPadding = '96px'
    } else if (p.bottomPadding === 'Small') {
      bottomPadding = '64px'
    } else {
      bottomPadding = '0'
    }

    return `
      padding: ${topPadding} 0 ${bottomPadding};
      ${
        p.isHomePage
          ? `
        ${p.theme.breakpoints.up('md')} {
          padding-top: 1px; // applied for negative margins
        }
        .ResourceRow__Outer-Container {
          margin-top: -81px;
          ${p.theme.breakpoints.down('md')} {
            margin-top: -69px;
          }
          ${p.theme.breakpoints.down('sm')} {
            margin-top: 0;
          }
        }
      `
          : ``
      }

    `
  }}
  background: rgb(247, 247, 247);
  .ResourceRow__Card-Container {
    display: flex;
    justify-content: center;

    ${(p) => p.theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: start;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      max-width: 328px;
    }
  }

  .ResourceRow__Pill {
    position: absolute;
    top: 16px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 15px;
  }

  .ResourceRow__Pill {
    &--White {
      border: 1px solid #f4f4f5;
      right: 16px;
      background: rgb(255, 255, 255);
      color: rgb(0, 0, 0);
    }
    &--Dark {
      left: 16px;
      background: rgb(0, 0, 0);
      color: rgb(255, 255, 255);
    }
  }

  .ResourceRow__Image-Container {
    width: 100%;
    height: 216px;
    position: relative;

    ${(p) => p.theme.breakpoints.down('md')} {
      height: 171px;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
      height: 203px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      height: 185px;
    }

    img {
      object-fit: cover;
      object-position: center;
    }

    .ResourceRow__BackgroundImg {
      width: 100%;
      height: 100%;
    }
  }

  .ResourceRow__Text-Container {
    padding: 0 24px;
    position: relative;
    margin-top: 32px;

    > *:first-child {
      margin-top: 0;
    }

    ul,
    ol {
      list-style: disc;
      padding-left: 24px;
      li {
        margin-top: 8px;
        p {
          margin: 0;
        }
      }
    }

    ${(p) => p.theme.breakpoints.down('md')} {
      margin-top: 24px;
    }
  }

  .ResourceRow__Item-Date {
    color: rgba(0, 0, 0, 0.3);
    margin-left: auto;
  }

  .ResourceRow__Meta-Container {
    display: flex;
    align-items: center;
  }

  .ResourceRow__Item-Category {
    color: rgb(89, 90, 92);
    margin: 0;
  }

  .ResourceRow__Item-Heading,
  .ResourceRow__Item-Body {
    margin-top: 16px;
  }

  .ResourceRow__Item-Heading:hover {
    color: #0067df;
  }

  .ResourceRow__Item-Body {
    p,
    li {
      color: rgb(89, 90, 92);
    }
  }

  .ResourceRow__SeeAllItemsIntro {
    margin-top: 48px;
    text-align: center;
    margin: 48px auto 0;
    max-width: 701px;

    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 548px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      max-width: 329px;
    }
  }

  .ResourceRow__CTA-Container {
    text-align: center;
    margin-top: 24px;
  }
`
