import React from 'react'

// Styles
import { ContentBlockContainer } from './style'

// Components
import Button from '../button'
import GlobalContainer from '../globalContainer'
import Typography from '../typography'

// Helpers
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils'

const ContentBlock = (props: any) => {
  if (props.data) {
    let contentBlockTheme: string = 'Light',
      headline: string = '',
      body: any,
      cta:
        | { externalUrl: string; text: string; internalLink?: internalUrl }[]
        | undefined,
      cta1Style: string = 'primary',
      cta2Style: string = 'text-link',
      topPadding: string = 'None',
      bottomPadding: string = 'Large',
      orangeDivider: boolean = true,
      finePrintWithHyperlinks: any,
      textAlignment: string = 'center',
      useCase: string = ''
    if (props.data.contentBlockTheme)
      contentBlockTheme = props.data.contentBlockTheme
    if (props.data.headline) headline = props.data.headline
    if (props.useCase) useCase = props.useCase
    if (props.data.body) body = props.data.body
    if (props.data.cta) cta = props.data.cta
    if (props.data.cta1Style)
      cta1Style =
        props.data.cta1Style === 'Link'
          ? 'text-link'
          : props.data.cta1Style.toLowerCase()
    if (props.data.cta2Style)
      cta2Style =
        props.data.cta2Style === 'Link'
          ? 'text-link'
          : props.data.cta2Style.toLowerCase()
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.orangeDivider !== null)
      orangeDivider = props.data.orangeDivider
    if (props.data.finePrintWithHyperlinks)
      finePrintWithHyperlinks = props.data.finePrintWithHyperlinks
    if (props.data.textAlignment !== null && !props.data.textAlignment)
      textAlignment = 'left'

    return (
      <ContentBlockContainer
        data-cy="ContentBlock"
        className={`ContentBlock--${
          useCase && useCase === 'Resource' ? 'Grey' : contentBlockTheme
        }`}
        orangeDivider={orangeDivider}
        alignment={textAlignment}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
      >
        <GlobalContainer>
          <div className="ContentBlock__Inner-Container">
            {headline && (
              <Typography type="header2" className="ContentBlock__Headline">
                {formatInlineCode(headline)}
              </Typography>
            )}
            {body && (
              <Typography type="leadXS" className="ContentBlock__Body">
                {renderRichText(body, options)}
              </Typography>
            )}
            {cta && cta[0] && (
              <div className="ContentBlock__Buttons">
                <Button
                  icon={true}
                  href={
                    cta[0].internalLink
                      ? internalLinkHandler(cta[0].internalLink)
                      : cta[0].externalUrl
                  }
                  text={cta[0].text}
                  type={cta1Style}
                  size="small"
                  theme={contentBlockTheme === 'Dark' ? 'dark' : 'light'}
                  {...cta[0]}
                />
                {cta[1] && (
                  <Button
                    icon={true}
                    href={
                      cta[1].internalLink
                        ? internalLinkHandler(cta[1].internalLink)
                        : cta[1].externalUrl
                    }
                    text={cta[1].text}
                    type={cta2Style}
                    size="small"
                    theme={contentBlockTheme === 'Dark' ? 'dark' : 'light'}
                    {...cta[1]}
                  />
                )}
              </div>
            )}
            {finePrintWithHyperlinks && (
              <div className="ContentBlock__Fineprint-Container">
                <Typography
                  type="finePrint"
                  className="ContentBlock__Fineprint"
                >
                  {renderRichText(finePrintWithHyperlinks, options)}
                </Typography>
              </div>
            )}
          </div>
        </GlobalContainer>
      </ContentBlockContainer>
    )
  }
  return null
}

export default ContentBlock
