export const UseCases = {
  RESOURCE_LISTING_MAIN: 'Main',
  RESOURCE_LISTING_AR: 'Analyst Reports',
  RESOURCE_LISTING_WP: 'White Papers',
  RESOURCE_LISTING_CS: 'Case Studies',
  RESOURCE_LISTING_WEBINAR: 'Webinars',
  RESOURCE_LISTING_VIDEO: 'Videos',
  RESOURCE_LISTING_BROCHURE: 'Brochures',
  RESOURCE_LISTING_EVENTS: 'Events',
}
